import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MoreProducts from '../components/BestSelling/MoreProducts';
import ProductDetails from '../pages/productGallery/ProductDetails';
import ArtFormSection from "../components/SubmitArtWork/SubmitArtWork.jsx";
import NotFound from '../pages/NotFound';
import Home from '../pages/home/Home.js'
// import Blog from "./components/Blog";
import BlogContent from "../components/blogs/BlogContent";
import ConfirmSubscription from '../components/shared/Footer/ConfirmSubscription.js';

export default function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/more-products/:category" element={<MoreProducts />} />
                <Route path="/submit-artwork" element={<ArtFormSection />} />
                <Route path="/product-details/:productId" element={<ProductDetails />} />
                <Route path="/newsletter/confirm/:token" element={<ConfirmSubscription />} />
                <Route path="/blog/:title" element={<BlogContent />} />
                <Route path="*" element={<NotFound />} /> {/* 404 page */}
            </Routes>
        </Router>
    );
}
