// client/src/components/LoveHelp/LoveHelp.jsx
import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import product from '../../assets/png/printing.png';
import profile from '../../assets/png/Ellipse 1903.png';
import 'swiper/css';
import 'swiper/css/navigation';
import './inqueryForm.scss';
import gsap from 'gsap';
import testimonials from '../../data/reviews.json';
import { helpContainerAnimation } from '../../animation/gsapAnimations'; // Import the animation function
import axios from 'axios';

export default function LoveHelp({ onClose }) {
    
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        message: '',
    }); 

    const [submissionStatus, setSubmissionStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmissionStatus('Submitting...'); // Show submitting status

        try {
            const response = await axios.post('https://www.artiststrokes.in/backend/api/Inquiry/submit', formData);
            if (response.status === 201) {
                setSubmissionStatus('Form submitted successfully!'); // Show success message
                // Reset form data
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',
                    message: '',
                });
            }
        } catch (error) {
            setSubmissionStatus('Error submitting the form. Please try again.'); // Show error message
            console.error('Error submitting form:', error);
        }
    };
    const swiperRef = useRef(null);

    const handleClose = () => {
        gsap.to('.showCont', { opacity: 0, duration: 0.5, ease: "power2.in", onComplete: onClose });
    };

    // Add animation to the help container
    useEffect(() => {
        helpContainerAnimation('.help_container');
    }, []);

    // Function to handle read more functionality for testimonials
    const [showMore, setShowMore] = useState(false);

    const handleReadMore = () => {
        setShowMore(!showMore);
    };

    return (
        <div className="showCont">
            <div className="visible"></div>
            <div className="help_container">
                <div className="contact-form">
                    <h2>We'd love to help</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>

              {/* ============= Form ============== */}
              <form onSubmit={handleSubmit}>
                        <div className='name-wrapper'>
                            <div className="input-field">
                                <label htmlFor="firstName">First name</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    placeholder="First name"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="input-field">
                                <label htmlFor="lastName">Last name</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    placeholder="Last name"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            placeholder="sample@gmail.com"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />

                        <label htmlFor="phoneNumber">Phone number</label>
                        <input
                            type="text"
                            id="phoneNumber"
                            placeholder="+91 0000000000"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                        />

                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            placeholder="Leave us a message here..."
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        ></textarea>

                        <button type="submit">Send a message</button>
                    </form>

                    {submissionStatus && <p>{submissionStatus}</p>}
                </div>

                {/* Swiper for Testimonials */}
                <div className="swiper-container" ref={swiperRef}>
                    <Swiper
                        slidesPerView={1}
                        navigation={{
                            prevEl: '.prev-button',
                            nextEl: '.next-button',
                        }}
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = '.prev-button';
                            swiper.params.navigation.nextEl = '.next-button';
                        }}
                        modules={[Navigation]}
                    >
                        {testimonials.map((testimonial, index) => (
                            <SwiperSlide key={index}>
                                <div className="testimonial">
                                    <div className="img-container">
                                        <img src={product} alt="Product" className="product-img" />
                                        <div className="review-section">
                                            <div>
                                                <div className="testimonial-info">
                                                    <div className='reviewImg'>
                                                        <img src={profile} alt="Profile" />
                                                    </div>

                                                    <div>
                                                        <h6><strong>{testimonial.name}</strong></h6>
                                                        <p>{testimonial.location}</p>
                                                        <p>{'⭐'.repeat(testimonial.rating)}</p>
                                                    </div>
                                                </div>

                                                {window.innerWidth > 425 ? (
                                                    <p>{testimonial.review}</p>
                                                ) : (
                                                    <>
                                                        <p className='read_review'>{showMore ? testimonial.review : `${testimonial.review.substring(0, 100)}...`}</p>
                                                        <button className='reed_more_btn' onClick={handleReadMore}>{showMore ? 'Read Less' : 'Read More'}</button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <button className="close-button" onClick={handleClose}>X</button>
                    <div className="swiper-navigation">
                        <button className="swiper-button prev-button">&larr;</button>
                        <button className="swiper-button next-button">&rarr;</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
