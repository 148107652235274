import React, { useState, useEffect } from 'react';
import './Footer.scss';
import gmail from '../../../assets/png/gmail.png';

export default function Footer() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    // Load reCAPTCHA script on component mount
    useEffect(() => {
        const loadRecaptchaScript = () => {
            if (!document.querySelector("script[src='https://www.google.com/recaptcha/api.js?render=6LdVOnQqAAAAAM5DOH6gbbwNpGuOoDecx8rFRe3R']")) {
                const script = document.createElement('script');
                script.src = 'https://www.google.com/recaptcha/api.js?render=6LdVOnQqAAAAAM5DOH6gbbwNpGuOoDecx8rFRe3R';
                script.async = true;
                document.body.appendChild(script);
            }
        };

        loadRecaptchaScript();
    }, []);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleCaptchaAndSubscribe = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');

        try {
            if (window.grecaptcha) {
                const token = await window.grecaptcha.execute('6LdVOnQqAAAAAM5DOH6gbbwNpGuOoDecx8rFRe3R', { action: 'subscribe' });
                await handleSubscribe(token);
            } else {
                setMessage('reCAPTCHA failed to load. Please try again.');
            }
        } catch (error) {
            console.error('Error with reCAPTCHA:', error);
            setMessage('Error with reCAPTCHA. Please refresh the page and try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleSubscribe = async (captchaToken) => {
        try {
            const response = await fetch('https://www.artiststrokes.in/backend/api/newsletter/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, captchaToken }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage('Subscribed successfully! Thank you for joining us!');
                setEmail('');
            } else {
                setMessage(data.message || 'Subscription failed.');
            }
        } catch (error) {
            console.error('Error subscribing:', error);
            setMessage('An error occurred. Please try again later.');
        }
    };

    return (
        <footer>
            <div className="container">
                <div className="foot-cont" style={{ justifyContent: 'space-between' }}>
                    <div className='helloCont'>
                        <div className="col">
                            <h3 className='playfair headgreen'>Say Hello</h3>
                            <div className='news'>
                                <h6>Stay updated on news</h6>
                                <div className="news_btn">
                                    <input
                                        type="email"
                                        placeholder='Enter Email'
                                        value={email}
                                        onChange={handleEmailChange}
                                        required
                                    />
                                    <button onClick={handleCaptchaAndSubscribe} disabled={loading}>
                                        {loading ? 'Subscribing...' : 'Subscribe Now'}
                                    </button>
                                </div>
                                {message && <p className='lable'>{message}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className='groupList'>
                            <h5 className='playfair'>For Business</h5>
                            <ul>
                                <li><a href="mailto: artiststrokes2020@gmail.com"><img src={gmail} alt="artiststrokes gmail" /></a></li>
                            </ul>
                        </div>
                        <div className='groupList'>
                            <h5 className='playfair'>Follow Us</h5>
                            <ul>
                                <li><a href="https://www.instagram.com/artissstrokes_india/" target='_blank' rel="noreferrer">Instagram</a></li>
                                <li><a href="https://www.facebook.com/profile.php?id=100064276054350&mibextid=ZbWKwL" target='_blank' rel="noreferrer">Facebook</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <ul className='contactList'>
                            <div className='groupList'>
                                <h5 className='playfair'>Legal</h5>
                                <ul>
                                    <li><a href="/#">Cookie Policy</a></li>
                                    <li><a href="/#">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className="row bottom-strap" style={{ padding: '30px 0px 0' }}>
                    <div className="col-6">
                        <p className="copyright">&copy; 2024 Artists Strokes. All Rights Reserved</p>
                    </div>
                    <div className="col-6">
                        <p>Designed and Developed by <a href="https://www.yashwebdesign.com" target='_blank' rel="noreferrer">Yashwebdesign</a></p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
