// productBuying.jsx
import React, { useState, useEffect } from 'react';
import './form.scss';
import data from '../../data/products.json';

const SubmitProductForm = ({ productId, selectedSize }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    contactNo: '',
    altContactNo: '',
    productId: productId || '', // Set productId from props
    productSize: selectedSize || '', // Set productSize from props
    flatNo: '',
    streetAddress: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  // Update product size whenever selectedSize changes
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      productSize: selectedSize,
    }));
  }, [selectedSize]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatusMessage('Submitting...');

    try {
      const response = await fetch('https://www.artiststrokes.in/backend/api/product/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatusMessage('Form submitted successfully!');
        setFormData({
          fullName: '',
          contactNo: '',
          altContactNo: '',
          productId: productId,
          productSize: '', // Reset size as needed
          flatNo: '',
          streetAddress: '',
          city: '',
          state: '',
          pincode: '',
          country: ''
        });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit form');
      }
    } catch (error) {
      setStatusMessage(`Error submitting form: ${error.message}`);
      console.error('Error while submitting the form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} id='productBuying'>
      <div className="row">
        <div className='inputGroup'>
          <label>Full Name</label><br />
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </div>
        <div className='inputGroup'>
          <label>Product ID</label><br />
          <input
            type="text"
            name="productId"
            value={formData.productId}
            readOnly
          />
        </div>
      </div>

      <div className="row">
        <div className='inputGroup'>
          <label>Contact Number</label><br />
          <input
            type="text"
            name="contactNo"
            value={formData.contactNo}
            onChange={handleChange}
            required
          />
        </div>
        <div className='inputGroup'>
          <label>Selected Size</label><br />
          <input
            type="text"
            name="productSize"
            value={formData.productSize}
            readOnly // Make it read-only or editable based on your needs
          />
        </div>
      </div>

      <fieldset>
        <legend>Address</legend>
        <div className="row" style={{ marginBottom: '15px' }}>
          <input
            type="text"
            name="flatNo"
            placeholder='Flat Number'
            value={formData.flatNo}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="streetAddress"
            placeholder='Street Address'
            value={formData.streetAddress}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="city"
            placeholder='City'
            value={formData.city}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="state"
            placeholder='State'
            value={formData.state}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="country"
            placeholder='Country'
            value={formData.country}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="pincode"
            placeholder='Pincode'
            value={formData.pincode}
            onChange={handleChange}
            required
          />
        </div>
      </fieldset>



      <div className='btnWrapper'>
        <button type="submit" disabled={isSubmitting} className='BuyNow'>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </div>

      {statusMessage && <p className="statusMessage">{statusMessage}</p>}
    </form>
  );
};

export default SubmitProductForm;
