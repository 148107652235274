import React, { useState } from 'react';
import './SubmitArtWorkForm.scss';
import axios from 'axios';

export default function SubmitArtWorkForm() {
    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        size: '',
        message: '',
    });
    const [artworkFiles, setArtworkFiles] = useState([]);
    const [submissionStatus, setSubmissionStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        setArtworkFiles(Array.from(e.target.files));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmissionStatus('Submitting...');

        const formDataToSend = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            formDataToSend.append(key, value);
        });

        artworkFiles.forEach((file) => {
            formDataToSend.append('artworkFiles', file);
        });

        try {
            const response = await axios.post('/backend/api/artwork/submit', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 201) {
                setSubmissionStatus('Form submitted successfully!');
                setFormData({
                    name: '',
                    phoneNumber: '',
                    email: '',
                    size: '',
                    message: '',
                });
                setArtworkFiles([]);
            }
        } catch (error) {
            setSubmissionStatus('Error submitting the form. Please try again.');
            console.error('Error submitting artwork:', error);
        }
    };

    return (
        <div className="form_wrapper">
            <form className="form-content" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group">
                        <label>Your name</label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Enter name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Phone Number</label>
                        <input
                            type="tel"
                            name="phoneNumber"
                            placeholder="+91 0000000000"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            placeholder="artist@gmail.com"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Your Artwork</label>
                        <button type="button" className="upload-btn" onClick={() => document.querySelector('input[type="file"]').click()}>
                            Upload Artwork
                        </button>
                        <input
                            type="file"
                            name="artworkFiles"
                            className="upload-input"
                            onChange={handleFileChange}
                            accept="image/*,application/pdf"
                            multiple
                            required
                        />
                        {artworkFiles.length > 0 && (
                            <ul className="uploaded-files-list">
                                {artworkFiles.map((file, index) => (
                                    <li key={index}>{file.name}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Select Size</label>
                        <select
                            name="size"
                            value={formData.size}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select size</option>
                            <option value="11x14">11 x 14</option>
                            <option value="12x16">12 x 16</option>
                            <option value="16x20">16 x 20</option>
                            <option value="18x24">18 x 24</option>
                            <option value="20x24">20 x 24</option>
                            <option value="24x30">24 x 30</option>
                            <option value="30x40">30 x 40</option>
                            <option value="36x48">36 x 48</option>
                            <option value="48x60">48 x 60</option>
                        </select>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Message</label>
                        <textarea
                            name="message"
                            placeholder="Leave us a message..."
                            value={formData.message}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <button type="submit" className="submit-btn">
                    Send a message
                </button>
            </form>
            {submissionStatus && <p>{submissionStatus}</p>}
        </div>
    );
}
